import axios from 'axios';
import {
    FETCH_DATA,
    FETCH_FOOTER,
    FETCH_OFFER,
    SET_IS_MODAL_OPEN,
    BASE_PATH,
    SET_STATUS,
} from '../constants/index';

export const fetchData = () => async (dispatch) => axios.get(`${BASE_PATH}/api/compact-auto`)
    .then((res) => dispatch({
        type: FETCH_DATA,
        payload: res.data,
    }))
    .catch(() => {});

export const fetchFooter = () => async (dispatch) => axios.get(`${BASE_PATH}/text/life-rb-adult/footer/1`)
    .then((res) => dispatch({
        type: FETCH_FOOTER,
        payload: res.data,
    }))
    .catch(() => {});

export const fetchOffer = () => async (dispatch) => axios.get(`${BASE_PATH}/text/life-rb-adult/oferta/1`)
    .then((res) => dispatch({
        type: FETCH_OFFER,
        payload: res.data,
    }))
    .catch(() => {});

export const setModalIsOpen = (state, contentId = null) => ({
    type: SET_IS_MODAL_OPEN,
    payload: {
        isModalOpen: state,
        modalContentId: contentId,
    },
});

export const getStatus = () => async (dispatch) => axios.get(`${BASE_PATH}/api/status`)
    .then((res) => {
        if (res.status === 200 && res.data) {
            dispatch({
                type: SET_STATUS,
                payload: res.data,
            });
        }
    })
    .catch(() => {});
