import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import './Header.sass';

import logo from './images/logo.png';

const Header = () => {
    const cnHeader = cn('Header');

    return (
        <header className={cnHeader()}>
            <Link to="/" className={cnHeader('Logo')}>
                <img className={cnHeader('LogoImg')} src={logo} alt="logo" />
                <span className={cnHeader('LogoText')}>RedStreet</span>
            </Link>
            <span className={cnHeader('Age')}>18+</span>
        </header>
    );
};

export default Header;
