import {
    FETCH_DATA,
    FETCH_FOOTER,
    FETCH_OFFER,
    SET_IS_MODAL_OPEN,
    SET_STATUS,
} from '../constants';

const initialState = {
    persons: [],
    user: null,
    footer: '',
    offer: '',
    isModalOpen: false,
    modalContentId: null,
    isAuth: false,
    flow: '',
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
    case FETCH_DATA:
        return {
            ...state,
            persons: payload.categories?.[0]?.contents,
            user: payload.user,
            isAuth: payload.authorized,
        };

    case FETCH_FOOTER:
        return {
            ...state,
            footer: payload,
        };

    case FETCH_OFFER:
        return {
            ...state,
            offer: payload,
        };

    case SET_IS_MODAL_OPEN:
        return { ...state, ...payload };

    case SET_STATUS:
        return {
            ...state,
            flow: payload.flow_url,
            isAuth: payload.authorized,
        };

    default:
        return state;
    }
};
