import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOffer } from '../actions/actionsContent';

const OfferPage = () => {
    const dispatch = useDispatch();
    const offer = useSelector((state) => state.offer);

    useEffect(() => {
        dispatch(fetchOffer());
    }, []);

    return (
        <main>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: offer }} />
        </main>
    );
};

export default OfferPage;
