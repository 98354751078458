import { useDispatch, useSelector } from 'react-redux';
import { setModalIsOpen } from '../actions/actionsContent';

export default () => {
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.isAuth);
    const flow = useSelector((state) => state.flow);

    const openModalHandle = (contentId) => () => {
        if (isAuth) {
            dispatch(setModalIsOpen(true, contentId));
            return;
        }

        window.location.href = flow;
    };

    return { openModalHandle };
};
