import React from 'react';
import { cn } from '@bem-react/classname';
import './Main.sass';
import VideoList from '../VideoList/VideoList';

const Main = () => {
    const cnMain = cn('Main');

    return (
        <main className={cnMain()}>
            <VideoList />
        </main>
    );
};

export default Main;
