/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import { render } from 'react-dom';
import 'normalize.css';
import { initializeApp } from 'firebase/app';
import './components/common/styles/main.sass';
import Root from './root';
import { portalsInit } from './utils/portals';
import { portalsSelectors } from './constants/portals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { firebaseRegistration } from './utils/firebase';
import { firebaseConfig } from './constants/firebase';

portalsInit(portalsSelectors);

initializeApp(firebaseConfig);

const mounthNode = document.getElementById('root');
render(<Root />, mounthNode);

// PWA
serviceWorkerRegistration.register({
    onSuccess: firebaseRegistration,
    onUpdate: firebaseRegistration,
});
