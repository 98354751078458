import React from 'react';
import { useSelector } from 'react-redux';
import { cn } from '@bem-react/classname';

import './Footer.sass';

const Footer = () => {
    const cnFooter = cn('Footer');
    const footer = useSelector((state) => state.footer);

    return (
        <div className={cnFooter()}>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={cnFooter('Text')} dangerouslySetInnerHTML={{ __html: footer }} />
        </div>
    );
};

export default Footer;
