import React from 'react';
import { cn } from '@bem-react/classname';
import PropTypes from 'prop-types';
import { ReactComponent as PromoLogoSVG } from '../common/images/promo-logo.svg';
import './PromoBannerItem.sass';

const PromoBannerItem = ({ image }) => {
    const cnPromoBannerItem = cn('PromoBannerItem');

    return (
        <div className={cnPromoBannerItem()}>
            <div className={cnPromoBannerItem('PromoLogoIcon')}>
                <PromoLogoSVG />
            </div>
            <img className={cnPromoBannerItem('Image')} src={image} alt="" />
        </div>
    );
};

PromoBannerItem.propTypes = {
    image: PropTypes.string.isRequired,
};

export default PromoBannerItem;
