import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

function Portal(props) {
    const { children, selector } = props;

    const [mounted, setMounted] = useState(false);

    const ref = useRef(null);

    const refCurrent = ref?.current;

    useEffect(() => {
        ref.current = document.querySelector(`#${selector}`);
        setMounted(true);
    }, [selector]);

    return (mounted && refCurrent) ? createPortal(children, refCurrent) : null;
}

export default Portal;
