import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cn } from '@bem-react/classname';
import ReactModal from 'react-modal';
import { setModalIsOpen } from '../../actions/actionsContent';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

import './VideoModal.sass';

ReactModal.setAppElement('#modal');

const VideoModal = () => {
    const cnVideoModal = cn('VideoModal');
    const dispatch = useDispatch();
    const isModalOpen = useSelector((state) => state.isModalOpen);
    const modalContentId = useSelector((state) => state.modalContentId);

    const closeHandle = () => {
        dispatch(setModalIsOpen(false));
    };

    return (
        <ReactModal
            onRequestClose={closeHandle}
            closeTimeoutMS={100}
            className={cnVideoModal('Modal')}
            isOpen={isModalOpen}
            parentSelector={() => document.querySelector('#modal')}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    zIndex: 9999,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
        >
            <button
                type="button"
                onClick={closeHandle}
                className={cnVideoModal('Close')}
            >
                &nbsp;
            </button>
            {modalContentId
                ? <VideoPlayer extraClass={cnVideoModal('Player')} contentId={modalContentId} />
                : <div>loading...</div>}
        </ReactModal>
    );
};

export default VideoModal;
