import React from 'react';
import Main from '../components/Main/Main';
import VideoModal from '../components/VideoModal/VideoModal';

const HomePage = () => (
    <>
        <Main />
        <VideoModal />
    </>
);

export default HomePage;
