// eslint-disable-next-line import/prefer-default-export
export const portalsInit = (selectors) => {
    if (Array.isArray(selectors)) {
        return selectors?.map((item) => {
            const div = document.createElement('div');
            div.id = item;
            document.body.appendChild(div);
            return null;
        });
    }
    return null;
};
