import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './components/App/App';
import store from './store/index';
import { BASE_PATH } from './constants';

const Root = () => (
    <Provider store={store}>
        <BrowserRouter basename={BASE_PATH}>
            <App />
        </BrowserRouter>
    </Provider>
);

export default Root;
