import React, { useState, useEffect } from 'react';
import { cn } from '@bem-react/classname';
import PromoBannerItem from '../PromoBannerItem/PromoBannerItem';
import promoImg1 from '../common/images/promo-1.png';
import promoImg2 from '../common/images/promo-2.png';
import promoImg3 from '../common/images/promo-3.png';
import promoImg4 from '../common/images/promo-4.png';
import './PromoBanner.sass';

const items = [
    { image: promoImg1 },
    { image: promoImg2 },
    { image: promoImg3 },
    { image: promoImg4 },
];

const link = 'http://l.funzone.by/subslp/flow/l_premium_playboy?lpid=98';

const PromoBanner = () => {
    const [randomItem, setRandomItem] = useState(null);
    const cnPromoBanner = cn('PromoBanner');

    useEffect(() => {
        const getRandomItem = () => {
            const shuffledItems = [...items].sort(() => 0.5 - Math.random());
            setRandomItem(shuffledItems[0]);
        };

        getRandomItem();
    }, []);

    const handleClick = () => {
        window.location.replace(link);
    };
    return (
        <div
            onClick={handleClick}
            className={cnPromoBanner()}
            aria-hidden="true"
        >
            {randomItem && (
                <PromoBannerItem
                    image={randomItem.image}
                />
            )}
        </div>
    );
};

export default PromoBanner;
