import { getToken } from 'firebase/messaging';
import { getMessaging, isSupported } from 'firebase/messaging/sw';
import Cookies from 'js-cookie';

import APILink from '../constants/api';
import CookieName from '../constants/cookies';
import { firebaseProjectToken, firebaseVapidKey } from '../constants/firebase';

export const firebaseErrorLog = (error) => {
    const errorDescription = error || 'неизвестна';
    // eslint-disable-next-line no-console
    console.error('PUSH Уведомления Firebase. Ошибка: ', errorDescription);
};

export const firebaseTokenFetch = (args) => {
    const {
        token,
        oldToken = null,
        msisdn = null,
    } = args;

    fetch(APILink.FIREBASE_TOKEN, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Project-Token': firebaseProjectToken,
        },
        body: JSON.stringify({
            device: 'web',
            token,
            old_token: oldToken,
            msisdn,
        }),
    })
        .then(({ ok, status }) => {
            if (ok) {
                Cookies.set(CookieName.FIREBASE_TOKEN, token);
                if (msisdn) {
                    Cookies.set(CookieName.FIREBASE_MSISDN, `${msisdn}`);
                }
            } else {
                firebaseErrorLog(`Статус ошибки: ${status}`);
            }
        })
        .catch(firebaseErrorLog);
};

export const firebaseRegistration = (registration) => {
    const messaging = getMessaging();

    isSupported()
        .then(async () => {
            getToken(messaging, {
                vapidKey: firebaseVapidKey,
                serviceWorkerRegistration: registration,
            })
                .then((token) => {
                    const oldToken = Cookies.get(CookieName.FIREBASE_TOKEN);
                    const msisdn = Cookies.get(CookieName.FIREBASE_MSISDN) || null;
                    const isOldToken = token === oldToken;

                    if (!isOldToken) {
                        firebaseTokenFetch({ token, oldToken, msisdn });
                    }
                })
                .catch(firebaseErrorLog);
        })
        .catch(firebaseErrorLog);
};

export const firebaseMsisdn = (msisdn) => {
    const firebaseToken = Cookies.get(CookieName.FIREBASE_TOKEN);
    const firebaseMSISDN = Cookies.get(CookieName.FIREBASE_MSISDN) || '';
    const isOldMSISDN = `${msisdn}` === firebaseMSISDN;
    if (firebaseToken && msisdn && !isOldMSISDN) {
        firebaseTokenFetch({
            token: firebaseToken,
            oldToken: firebaseToken,
            msisdn,
        });
    } else {
        Cookies.set(CookieName.FIREBASE_MSISDN, `${msisdn}`);
    }
};
