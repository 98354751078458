import React, { useState } from 'react';
import { cn } from '@bem-react/classname';
import Lazyload from 'react-lazyload';
import { useSelector, useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import { useMediaQuery } from 'react-responsive';
import useModalHandle from '../../hooks/useModalHandle';
import videoIcon from './images/video.svg';
import { BASE_PATH } from '../../constants';
import PromoBanner from '../PromoBanner/PromoBanner';

import './VideoList.sass';

const VideoList = () => {
    const cnVideoList = cn('VideoList');
    const dispatch = useDispatch();
    const persons = useSelector((state) => state.persons);
    const { openModalHandle } = useModalHandle();
    const [pageLimit, setPageLimit] = useState(8);

    const isTablet = useMediaQuery({ query: '(min-width: 768px)' });

    const handleButtonClick = (id) => () => {
        dispatch(openModalHandle(id));
    };

    const handleMoreButtun = () => {
        const top = window.scrollY;
        setPageLimit((state) => state + 8);
        setImmediate(() => {
            window.scrollTo(0, top);
        });
    };

    const renderItem = (image, id, title, index) => {
        const shouldRenderBanner = isTablet ? ((index + 1) % 4 === 0) : (index % 3 === 0);

        return (
            <React.Fragment key={id}>
                <div
                    className={cnVideoList('Item')}
                >
                    <button
                        onClick={handleButtonClick(id)}
                        className={cnVideoList('Button')}
                        type="button"
                    >
                        <SVG
                            className={cnVideoList('Icon')}
                            src={videoIcon}
                        />
                        <Lazyload
                            once
                            placeholder={<div className={cnVideoList('Loader')} />}
                            classNamePrefix={cnVideoList('Lazyload')}
                        >
                            <div
                                className={cnVideoList('Image')}
                                style={{ backgroundImage: `url(${BASE_PATH}${image})` }}
                            />
                            <div
                                className={cnVideoList('Divider')}
                            />
                        </Lazyload>
                        <span className={cnVideoList('Title')}>{title}</span>
                    </button>
                </div>
                {shouldRenderBanner ? (
                    <PromoBanner />
                ) : null}
            </React.Fragment>
        );
    };

    return (
        <>
            <div className={cnVideoList('List')}>
                {persons.filter((item, index) => index < pageLimit)
                    .map((item, index) => (
                        renderItem(`/storage/free/${item.previews?.[0]?.link}`, item.id, item.name, index)
                    ))}
            </div>
            {persons.length >= pageLimit ? (
                <div className={cnVideoList('ButtonWrapper')}>
                    <button
                        className={cnVideoList('More')}
                        onClick={handleMoreButtun}
                        type="button"
                    >
                        Ещё видео
                    </button>
                </div>
            ) : null}
        </>
    );
};

export default VideoList;
