import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { PrivacyPolicy } from '@b3w/pp-portal';
import '@b3w/pp-portal/dist/portal-bundle.css';
import { Statistic } from '@b3w/react-statistic';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Routes from '../Routes/Routes';
import { BASE_PATH } from '../../constants';
import { fetchData, fetchFooter, getStatus } from '../../actions/actionsContent';
import './App.sass';
import AddToHomeScreen from '../AddToHomeScreen/AddToHomeScreen';
import useFirebase from '../../hooks/useFirebase';

const App = () => {
    const dispatch = useDispatch();
    const [ppText, setPpText] = useState('');
    const [supportText, setSupportText] = useState('');

    useFirebase();

    useEffect(() => {
        dispatch(fetchData());
        dispatch(getStatus());
        dispatch(fetchFooter());

        axios.get(`${BASE_PATH}/text/common-text-service/privacyfooter/1`)
            .then((res) => {
                if (res.data) {
                    const match = res.data.match(/%.*%/);
                    const substr = match ? match[0] : '';
                    const linkName = substr ? substr.slice(1, substr.length - 1) : '';
                    const linkHtml = `<a href="http://lk.funzone.by/privacypolicy/?service=life-rb-adult">
                        ${linkName}</a>`;
                    setPpText(res.data.replace(substr, linkHtml));
                }
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        axios.get(`${BASE_PATH}/text/life-rb-adult/header-support/1`)
            .then((res) => {
                setSupportText(res.data);
            });
    }, []);

    return (
        <>
            <Statistic
                path={`${BASE_PATH}/referer/statistic`}
                timeoutMinutes={1}
                from
            />
            <div className="HeaderSupport" dangerouslySetInnerHTML={{ __html: supportText }} />
            {ppText && <PrivacyPolicy dangerousText={ppText} adoption />}
            <Header />
            <Routes />
            <Footer />
            <AddToHomeScreen />
        </>
    );
};

export default App;
