import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { firebaseMsisdn } from '../utils/firebase';

function useFirebase() {
    const data = useSelector((state) => state.user);
    const msisdn = data?.msisdn;

    useEffect(() => {
        if (msisdn) {
            firebaseMsisdn(msisdn);
        }
    }, [data]);
}

export default useFirebase;
