import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from '../../pages/HomePage';
import OfferPage from '../../pages/OfferPage';
import ErrorPage from '../../pages/ErrorPage';

const Routes = () => (
    <Switch>
        <Route path="/user/conditions" component={OfferPage} />
        <Route path="/" exact component={HomePage} />
        <Route component={ErrorPage} />
    </Switch>
);

export default Routes;
