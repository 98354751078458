export const firebaseProjectToken = 'ac661187-c0fa-4ecb-9d65-d203dbc59841';

export const firebaseVapidKey = 'BLoVGn7YahRGnTAb409gAa7Gaqyv0ELUNA2XTM_UXGIJOVeDAgHFjjsFExRauTL052ZL22iD9oo5gUvWbqZ3KsY';

export const firebaseConfig = {
    apiKey: 'AIzaSyCzA0KHVhWlyvlRaRGezBUnzc2vYXexB-k',
    authDomain: 'redstreet-rb-life.firebaseapp.com',
    projectId: 'redstreet-rb-life',
    storageBucket: 'redstreet-rb-life.appspot.com',
    messagingSenderId: '229445065558',
    appId: '1:229445065558:web:4e707c3829c48952e694f7',
};
