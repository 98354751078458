import React from 'react';

const ErrorPage = () => (
    <main style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }}
    >
        <div style={{ fontSize: 22, marginBottom: 20 }}>Страница не найдена</div>
        <div style={{ fontSize: 60 }}>404</div>
    </main>
);

export default ErrorPage;
