/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import { BASE_PATH } from '../../constants';
import PromoBanner from '../PromoBanner/PromoBanner';

const VideoPlayer = ({ extraClass, contentId }) => {
    const cnVideoPlayer = cn('VideoPlayer');
    const isAuth = useSelector((state) => state.isAuth);
    const persons = useSelector((state) => state.persons);
    const flow = useSelector((state) => state.flow, shallowEqual);
    const { uidx, file_hash: fileHash } = persons.filter((person) => person.id === contentId)?.[0];

    if (!isAuth) window.location.href = flow;

    return (
        <div className={classnames(cnVideoPlayer(), extraClass)}>
            <video
                controls
                preload="metadata"
            >
                <source
                    src={`${BASE_PATH}/storage/d/${uidx}/${fileHash}`}
                    type="video/mp4"
                />
            </video>
            <PromoBanner />
        </div>
    );
};

VideoPlayer.propTypes = {
    extraClass: PropTypes.string,
    contentId: PropTypes.number.isRequired,
};

VideoPlayer.defaultProps = {
    extraClass: '',
};

export default VideoPlayer;
